.root {
  bottom: 15px;
  display: flex;
  left: 0;
  position: fixed;
  transition: transform 0.2s ease-out;
  z-index: 2600;
}

.rootCategory {
  bottom: 90px;
  display: flex;
  left: 0;
  position: fixed;
  transition: transform 0.2s ease-out;
  z-index: 2600;
}

.rootCategory span {
  cursor: pointer;
  opacity: 0.75;
  text-decoration: underline;
  transition: opacity 0.2s ease-in;

  &:hover {
    opacity: 1;
  }
}

.closed {
  transform: translateX(
    calc(-100% + var(--grid-outer-space) + var(--grid-gutter))
  );
}

.content {
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 13px;
  padding: 0.5em 0.75em;
}

.toggle {
  background-color: rgba(0, 0, 0, 0.75);
  border-left: 1px solid white;
  width: calc(var(--grid-outer-space) + var(--grid-gutter));
}

.root p {
  margin: 0.2em 0;
}

.root strong {
  font-weight: bold;
}

.root a {
  cursor: pointer;
  opacity: 0.75;
  text-decoration: underline;
  transition: opacity 0.2s ease-in;

  &:hover {
    opacity: 1;
  }
}

.breakpoint::before {
  @media (--up_to_S) {
    content: "< S";
    opacity: 0.5;
  }

  @media (--S_to_M) {
    content: "S";
  }

  @media (--M_to_L) {
    content: "M";
  }

  @media (--L_to_XL) {
    content: "L";
  }

  @media (--XL_to_XXL) {
    content: "XL";
  }

  @media (--XXL_to_XXXL) {
    content: "XXL";
  }

  @media (--XXXL_and_up) {
    content: "XXXL";
  }
}
